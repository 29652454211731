import { Atom, Cmp, Mod } from ":mods";
import { createStore } from "solid-js/store";
import { For, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import { AMaterialTaskContent, MaterialTaskDesignView } from "../../../../models";
import clone from "clone";

//? response and request payloads
type RecievedPayload = AMaterialTaskContent<{
  image: any;
  description: string;
  title: string;
  options: { id: number; value: string }[];
  option_answer: number;
}>;
type RequestPayload = AMaterialTaskContent<{
  image?: boolean;
  title?: string;
  description?: string;
  options?: number;
  option_answer?: number;
}>;
type RequestPayloadSection = Partial<RequestPayload[0]>;
type RequestPayloadSectionContent = Partial<RequestPayloadSection["content"]>;
type RequestPayloadSectionContentItem = Partial<RequestPayloadSectionContent[0]>;
// -------------
//? potential partenrship
// -------------
export default function (props: MaterialTaskDesignView<RecievedPayload, RequestPayloadSectionContent>) {
  // setup vars before content
  let images = [];

  // track parent content
  const content = createMemo(() => {
    // on content updated or discarded
    images = [];
    return props.elements.data.content as RecievedPayload;
  });

  function onDataChange(idx: number, props: RequestPayloadSectionContentItem) {
    const items = content()[0].content as unknown as RequestPayloadSectionContent;
    items[idx] = { ...items[idx], ...props };
    //? this is where you process data before passing it to update
    for (const item of items) {
      // const new_item = { ...item, ...props } as RequestPayloadSectionContentItem;
      if (typeof item.image !== "boolean") {
        item.image = false;
      }
      if (typeof item.option_answer !== "number") {
        // delete item.options;
      } else {
        item.options = item.option_answer;
      }
      delete item.option_answer;
    }
    // pass data to update
    console.log("d :: ", items);
    onDataUpdate(items);
  }

  function onDataUpdate(data: RequestPayloadSectionContent) {
    const formData = new FormData();
    images.map((imagefile, i) => formData.append("images", imagefile, `tp03 image ${i + 1}`));
    formData.append("data", JSON.stringify(data));
    props.events?.onDataUpdate?.(formData, data);
  }

  return (
    <form class="">
      <For each={content()}>
        {(section, idx) => {
          return (
            <>
              <h1 class="text-39px font-700 mt-40px mb-20px">
                {"Potential Partnerships"}
                {/* {section.details.title || section.details[0]?.title || "Potential Partnerships"} */}
              </h1>
              <section class="flex flex-wrap  gap-2rem pr-0.8rem">
                <For each={section.content}>
                  {(content, idx) => {
                    const titleLen = content.title?.split(" ").length;
                    const fontStyle =
                      titleLen <= 3 ? "text-63px leading-72px h-60%  w-full " : "text-49px leading-48px h-60%  w-full";
                    return (
                      <div class="flex justify-center gap-2rem w-full h-full">
                        {/* first row */}
                        <div class="w-45% h-55vh">
                          {!!content.image && (
                            <Atom.Tasks.Image
                              class="w-full h-full"
                              options={{
                                addImagePlaceholder: "Upload Partner Logo or Image Here",
                                editable: props.options.editable,
                              }}
                              elements={{
                                images: images,
                                image: content.image,
                              }}
                              events={{
                                onImageChange: (e) => onDataChange(idx(), { image: e }),
                              }}
                            />
                          )}
                        </div>

                        <div class="w-55% h-55vh">
                          <div class="flex flex-col w-full h-full gap-20px">
                            <Atom.Tasks.Title
                              class={fontStyle}
                              elements={{
                                title: content.title,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                onChange: (e) => onDataChange(idx(), { title: e.target.value }),
                              }}
                            />
                            <Atom.Tasks.Dropdown
                              class="w-full h-55px "
                              placeholder="Select Type of Partner"
                              elements={{
                                options: content.options,
                                opt_ans: content.option_answer,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                onValueChange: (e) => onDataChange(idx(), { option_answer: e }),
                              }}
                            />
                            <Atom.Tasks.Description
                              class="w-full h-full"
                              elements={{
                                description: content.description,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </For>
              </section>
            </>
          );
        }}
      </For>
    </form>
  );
}
